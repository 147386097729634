@import "../../../assets/theme/_var.scss";

.lending_currency {
    &_head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            width: max-content;
            padding: 0rem 2rem;
        }
    }

    &_in {
        margin-top: 2rem;

        .row {
            margin: -1rem;

            >div {
                padding: 1rem;
            }
        }

        .field {
            input {
                margin-bottom: 2rem;
            }

        }

    }

    .table_sec {
        margin-top: 2rem;
    }
}
.lending_currency_head{
    margin-bottom: 2rem;
}
@import "../../../assets/theme/_var.scss";

.table_box {
  background: $tableBg;
  border-radius: 2rem;
  position: relative;
  backdrop-filter: blur(12px);
  padding-bottom: 3.2rem;

  h3 {
    padding: 2rem 3rem 2rem 3rem;
    font-size: 2.4rem;
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: 2.2rem;
    }

    @media (max-width: 575px) {
      font-size: 2rem;
    }
  }

  .table {
    background: transparent;
    vertical-align: middle;

    thead {
      tr {
        th {
          color: $white;
          background-color: #1b0d1e;
          font-size: 1.4rem;
          font-weight: 500;
          padding: 2rem 3rem;
          border-bottom: 0;
          white-space: nowrap;

          @media (max-width: 1679px) {
            padding: 1.5rem 2.5rem;
          }

          @media (max-width: 1399px) {
            padding: 1.2rem 2rem;
          }

          @media (max-width: 991px) {
            padding: 1rem 1.5rem;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 0.1rem solid rgba($white, 0.1);

        td {
          color: $white;
          font-size: 1.6rem;
          font-weight: 400;
          padding: 1.5rem 3rem;
          background: transparent;
          white-space: nowrap;


          p {
            display: block;
            color: $lightText;
            font-size: 1.4rem;
            font-weight: 200;
          }

          @media (max-width: 1679px) {
            padding: 1.5rem 2.5rem;
          }

          @media (max-width: 1399px) {
            padding: 1.2rem 2rem;
          }

          @media (max-width: 991px) {
            padding: 1rem 1.5rem;
          }

          @media (max-width: 767px) {
            font-size: 1.4rem;
          }

          @media (max-width: 575px) {
            font-size: 1.3rem;
          }

          .noRecord {
            text-align: center;

            @media (max-width:991px) {
              width: calc(100dvw - 6rem);
            }

            p {
              font-size: 1.5rem;
              font-weight: 400;
            }

            img {
              width: 14rem;
              height: 14rem;
            }
          }

          img,
          svg {
            width: 3.4rem;
            height: 3.4rem;
            flex-shrink: 0;
            margin-right: 1rem;

            @media (max-width:480px) {
              width: 2rem;
              height: 2rem;
              margin-right: 0.5rem;

            }
          }

          a {
            color: $white;

            svg {
              width: 1.8rem;
              height: 1.8rem;
              margin-left: 0.5rem;

              path {
                stroke: $baseclr;
              }
            }
          }

          .copy_btn {
            width: 1.8rem;
            height: 1.8rem;
            padding: 0;
            margin: 0;
            background-color: transparent;
            border: none;
            margin-left: 0.4rem;
          }
        }
      }
    }
  }
}
@import "../../../assets/theme/_var.scss";

.dashboard {
    background: $BoxOverlay;
    padding: 3.6rem 0rem 16.7rem 0rem;

    &_in {
        &_head {
            .inner_box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5rem;

                @media (max-width:767px) {
                    flex-direction: column;
                }
            }

            .head_inner {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;


                &:not(:last-child) {
                    margin-right: 9rem;

                    @media (max-width:767px) {
                        margin-right: 3.5rem;
                    }

                    @media (max-width:767px) {
                        margin-right: 0;
                        margin-bottom: 4rem;
                    }
                }

                p {
                    font-size: 1.6rem;
                    font-weight: 400;
                    margin-bottom: 1rem;

                    &.purple {
                        color: $baseclr;

                    }

                    &.blue {
                        color: $BlueFont;

                    }
                }

                h3 {
                    font-size: 3.4rem;
                    font-weight: 500;
                    color: $white;
                    font-variant-numeric: tabular-nums;

                    @media (max-width:991px) {
                        font-size: 2.7rem;
                    }

                    @media (max-width:767px) {
                        font-size: 2.2rem;
                    }

                    span {
                        color: $lightText;
                    }
                }

                .round_box,
                .blue_round_box {
                    width: 16.8rem;
                    height: 16.8rem;
                    border: 2.2rem solid $baseclr;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;


                    .inner_round {
                        background: rgba($baseclr, 0.1);
                        border: .3rem solid rgba($baseclr, 0.1);
                        width: 10.8rem;
                        height: 10.8rem;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }


                    h4 {
                        font-size: 1.6rem;
                        font-weight: 400;
                        color: $lightText;
                        margin-bottom: .6rem;

                    }

                    h5 {
                        font-size: 2.2rem;
                        font-weight: 600;
                        font-variant-numeric: tabular-nums;

                    }

                }

                .blue_round_box {
                    border: 2.2rem solid #2A6CD4;

                    .inner_round {
                        background: rgba(#2A6CD4, 0.1);
                        border: .3rem solid rgba(#2A6CD4, 0.1);
                    }
                }
            }

            .progress_box {
                background: #1b0d1e;
                border-radius: 1.6rem;
                max-width: 102.4rem;
                width: 100%;
                height: 6.4rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0rem 2.8rem;
                margin: 0 auto;

                h3 {
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $lightText;
                    flex-shrink: 0;

                    span {
                        margin-left: 0.4rem;
                        color: $white;
                        font-weight: 600;
                    }
                }

                h4 {
                    font-size: 1.6rem;
                    font-weight: 400;
                    flex-shrink: 0;
                    color: $white;

                }

                .progress {
                    background-color: rgba($white, 0.1);
                    padding: .3rem;
                    margin: 0rem 1.9rem;
                    width: 100%;

                    span {
                        background: $progress;
                        width: 100%;
                        border-radius: 0.6rem;
                        max-width: 100%;
                        width: 0%;
                        transition: 300ms ease-in-out;

                    }
                }
            }
        }
    }
}

.dashboard_content {
    margin-top: -9.6rem;
    padding-bottom: 11.7rem;

    .row {
        margin: -1.8rem;

        h2 {
            font-size: 2.5rem;
            font-weight: 400;
            flex-shrink: 0;
            color: $white;
        }

        >div {
            padding: 1.8rem;
        }
    }

    .content_table_box {
        h2 {
            font-size: 1.8rem;
            font-weight: 500;
            margin-bottom: 1rem;
            line-height: normal;

            @media (max-width:575px) {
                font-size: 1.6rem;

            }

        }

        &:not(:last-child) {
            margin-bottom: 2rem;

        }
    }
}

.checkbox {
    position: relative;
    z-index: 99;
}
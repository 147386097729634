@import "../../../../assets/theme/_var.scss";


.confirm_transaction {

  .success_box
  {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4.5rem;
    svg{
      width: 17.5rem;
      height: 12rem;
    }
    .buffring_img{
      animation: rotate 2s linear infinite;
      img{
        width: 17.5rem;
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    
    p{
      color: $lightText;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      margin-top: 2.6rem;
    }
  }
}
@import "../../../assets/theme/_var.scss";

.custom_btn {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.4rem;
    width: 15.8rem;
    border-radius: 5rem;
    color: $white;
    height: 5rem;
    background-color: $baseclr;
    border: 2px solid $baseclr;
    transition: 300ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 1199px) {
        height: 4rem;
        width: 12rem;
        font-size: 1.2rem;
    }

    &:hover {
        color: $baseclr;
        background-color: transparent;
    }

    &.light_bg_btn {
        background: #332036;
        border-color: transparent;

        img,
        svg {
            width: 2.8rem;
            height: 2.8rem;
        }

    }

    &.blue_btn {
        background-color: #2A6CD4;
        border: 2px solid #2A6CD4;

        &:hover {
            color: #2A6CD4;
            background-color: transparent;
            border: 2px solid #2A6CD4;
        }
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}
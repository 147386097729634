@import "../../assets/theme/_var.scss";

.sidebar {
    width: 30rem;
    position: fixed;
    top: 0rem;
    height: 100vh;
    left: 0;
    z-index: 10;
    background: #241128;
    padding: 2rem 3rem;
    transition: 0.3s all ease-in-out;


    &.active {
        transform: translateX(0%);
    }

    @media (max-width:767px) {
        transform: translateX(-100%);

    }

    .logo {
        max-width: 16.5rem;

        @media (max-width: 1579px) {
            max-width: 16rem;
        }

        @media (max-width: 1479px) {
            width: 14rem;
        }
    }


    ul {
        margin-top: 3rem;


        li {

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            a {
                padding: 1.5rem 3rem 1.5rem 1.5rem;
                width: 100%;
                font-size: 1.6rem;
                font-weight: 600;
                color: $white;
                backdrop-filter: blur(0.8rem);
                position: relative;
                z-index: 0;
                transition: 0.2s all ease-in-out;
                border-radius: 1rem;

                &::after {
                    content: "";
                    width: 1rem;
                    height: 1rem;
                    position: absolute;
                    z-index: -1;
                    top: 50%;
                    left: -3.3rem;
                    transform: translateY(-50%);
                    background-color: $baseclr;
                    transition: 0.2s all ease-in-out;
                    border-radius: 50%;
                    opacity: 0;
                }

                &:hover,
                &.active {
                    background-color: $baseclr;

                    &::after {
                        opacity: 1;
                    }
                }

            }
        }
    }


}
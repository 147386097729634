@import "../../../assets/theme/_var.scss";

.auth_layout {
    background-image: url("../../../assets/images/landing-page.png");
    overflow-x: clip;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    font-family: $secondaryfont;

    h2 {
        font-size: 5rem;
        font-weight: 700;
        line-height: 6rem;

        @media (max-width: 1579px) {
            font-size: 4.4rem;
            line-height: 5.6rem;
        }

        @media (max-width: 1479px) {
            font-size: 3.4rem;
            line-height: 4.6rem;
        }

        @media (max-width:1365px) {
            font-size: 2.8rem;
            line-height: 1.4;
        }

        span {
            color: $baseclr;
        }
    }

    .sub_text {
        font-family: $secondaryfont;
        font-size: 2rem;
        color: rgba($white, 0.8);
        font-weight: 400;
        line-height: 3rem;
        margin: 2rem 0 4rem;

        @media (max-width: 1579px) {
            font-size: 1.8rem;
            line-height: 2.8rem;
        }

        @media (max-width: 1479px) {
            font-size: 1.6rem;
            line-height: 2.6rem;
            margin: 1.2rem 0 3rem;
        }

        @media (max-width:1365px) {
            margin: 1rem 0 2.4rem;
        }
    }
}
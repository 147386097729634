@import '../../../../assets/theme/_var.scss';

.supply_withdraw {
    .modal-dialog {
        max-width: 54.2rem;

        .modal-content {
            padding: 1.6rem 3rem 3rem 3rem;

            .borrow_heading {
                position: relative;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;

                input {
                    font-size: 4.4rem;
                    font-weight: 500;
                    line-height: 4.6rem;
                    background: transparent;
                    color: $white;
                    text-align: center;
                    max-width: 26rem;

                    &::placeholder {
                        color: $white;
                    }

                }

                button {
                    right: -2.8rem;
                    top: 50%;
                    transform: translate(0%, -50%);
                    position: absolute;
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 1.6rem;
                    color: $lightText;
                    display: flex;
                    flex-direction: column;
                    background-color: transparent;
                    border: 0;
                }
            }

            .widthrow {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                img {
                    width: 5rem;
                    height: 5rem;
                }

                p {
                    font-weight: 400;
                    color: $lightText;
                    font-size: 1.4rem;
                    text-align: center;
                    margin-top: 2rem;
                    max-width: 38.2rem;
                    width: 100%;
                }
            }

            .nav {
                margin-top: 4rem;

                .nav-item {
                    width: 50%;
                    display: flex;
                    justify-content: center;

                    a {
                        padding: 0;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        text-align: center;
                        color: $lightText;
                        width: 100%;
                        border-bottom: 1px solid rgba($white, 0.1);
                        padding-bottom: 1.5rem;

                        &.active {
                            color: $white;
                            border-bottom: 2px solid $baseclr;
                        }
                    }
                }

            }

            .tab-content {
                padding-top: 2rem;

                .borrow_values {
                    padding: 2rem;
                    background: #1B0D1E;
                    border-radius: 1rem;

                    h3 {
                        font-size: 1.6rem;
                        font-weight: 600;
                        line-height: 2.4rem;
                        margin-bottom: 2rem;

                        svg {
                            margin-left: .6rem;
                        }
                    }

                    ul {
                        &.borrow_list {
                            padding-bottom: 2rem;
                            border-bottom: 1px solid rgba($white, .1);
                            margin-bottom: 2rem;
                        }

                        li {
                            display: flex;
                            justify-content: space-between;

                            &:not(:last-child) {
                                margin-bottom: 1rem;
                            }

                            h4 {
                                font-size: 1.6rem;
                                font-weight: 400;
                                line-height: 1.8rem;
                                color: $lightText;
                            }

                            h6 {
                                font-size: 1.6rem;
                                font-weight: 500;
                                line-height: 1.8rem;

                                svg {
                                    margin: 0 .6rem;
                                }
                            }
                        }
                    }

                    .progress_line {
                        background: rgba($white, 0.1);
                        border-radius: .6rem;
                        width: 100%;
                        height: 1.2rem;
                        padding: .3rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 2rem;

                        span {
                            background: #2bcf62;
                            border-radius: .6rem;
                            width: 0%;
                            max-width: 100%;
                            transition: 0.2s ease-in-out;
                            height: .6rem;
                        }
                    }

                }



                .currently_borrow {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 2rem;

                    h5 {
                        font-size: 1.6rem;
                        font-weight: 400;
                        line-height: 1.8rem;
                        color: $lightText;
                    }

                    label {
                        font-size: 1.6rem;
                        font-weight: 500;
                        line-height: 1.8rem;
                    }
                }


            }
        }
    }
}

.supply_btn {
    margin: 3rem 0rem 0rem 0rem;
    font-size: 1.4rem;
    font-weight: 600;
}
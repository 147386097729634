@import '../../../assets/theme/_var.scss';

.common_search {
  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }

  &_in {
    position: relative;

    .search_icon {

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1rem;

      svg {
        width: 2rem;
        height: 2rem;
      }

    }

    input {
      line-height: normal;
      max-width: 100%;
      width: 100%;
      height: 5rem;
      border-radius: 1rem;
      background-color: $borderclr;
      border: none;
      color: $white;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 0 1.5rem 0rem 3.5rem;

      @media (max-width:767px) {
        font-size: 1.4rem;
      }

      @media (max-width:575px) {
        font-size: 1.3rem;
      }


      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $white;
      }

    }
  }

  label {
    display: block;
    color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.5rem;

    @media (max-width:767px) {
      font-size: 1.4rem;
    }

    @media (max-width:575px) {
      font-size: 1.3rem;
    }

  }



}
@import "../../../assets/theme/_var.scss";

.dashboard_header {
    position: sticky;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(0.8rem);
    background: linear-gradient(153.28deg,
            rgba(41, 27, 43, 1) 0%,
            rgba(17, 20, 25, 0.4) 100%);
    padding: 2.2rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $secondaryfont;
    height: 9rem;

    @media (max-width:767px) {
        height: 7rem;

    }

    .overlay {
        width: 100%;
        height: 100dvh;
        background-color: rgba($black, 0.2);
        position: fixed;
        z-index: -2;
        top: 0;
        left: 0;
        display: none;

        &.active {
            display: block;
        }

    }

    .header_in {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo_box {
            margin-right: 2rem;

            .logo {
                max-width: 16.5rem;
                flex-shrink: 0;

                @media (max-width: 1579px) {
                    max-width: 16rem;
                }

                @media (max-width: 1479px) {
                    width: 14rem;
                }
            }
        }


        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 300ms ease-in-out;

            @media (max-width:767px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: start;
                background: #1b0d1e;
                position: absolute;
                left: 0;
                top: 0;
                padding: 11rem 4rem 4rem 4rem;
                height: 100dvh;
                max-width: 31.5rem;
                width: 100%;
                transform: translateX(-31.5rem);
                z-index: -1;

                &.active {
                    transform: translateX(0rem);
                    transition: 0.3s ease-in-out;
                }
            }

            li {
                &:not(:last-of-type) {
                    margin-right: 3rem;

                    @media (max-width:991px) {
                        margin-right: 1.5rem;
                    }

                    @media (max-width:767px) {
                        margin-right: 0rem;
                    }
                }

                @media (max-width:767px) {
                    margin-bottom: 2rem;
                    width: 100%;

                }

                a {
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $lightText;
                    position: relative;

                    @media (max-width:991px) {
                        font-size: 1.4rem;

                    }

                    @media (max-width:767px) {
                        margin-bottom: 2rem;
                        width: 100%;

                    }

                    &::after {
                        content: "";
                        background-color: #da41dd;
                        width: 100%;
                        height: .2rem;
                        position: absolute;
                        bottom: -3.2rem;
                        left: 0;
                        opacity: 0;

                        @media (max-width:991px) {
                            bottom: -0.8rem;
                        }
                    }

                    &.active {
                        color: $white;

                        &::after {
                            opacity: 1;

                        }
                    }
                }
            }
        }


    }
}

.btn_box {
    display: flex;

    @media (max-width:767px) {
        display: none;
    }

    .light_bg_btn {
        padding: .7rem 1.8rem .7rem .7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: max-content;
        font-size: 1.4rem;
        font-weight: 600;

        @media (max-width:575px) {
            padding: .7rem;

            span {
                display: none;
            }
        }

        img {
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 1rem;

            @media (max-width:575px) {
                margin-right: 0rem;
            }
        }

        &:not(:last-child) {
            margin-right: 1.8rem;

            @media (max-width:575px) {
                margin-right: 1rem;
            }

            @media (max-width:991px) {
                margin-bottom: 1rem;

            }
        }
    }
}

.responsive_btn_box {
    display: flex;

    @media (min-width:767px) {
        display: none;
    }

    @media (max-width:767px) {
        flex-direction: column;
        width: 100%;
        margin-top: auto;
    }

    .light_bg_btn {
        padding: .7rem 1.8rem .7rem .7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: max-content;
        font-size: 1.4rem;
        font-weight: 600;

        @media (max-width:767px) {
            width: 100%;
            justify-content: flex-start;
        }

        img {
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 1rem;

        }

        &:not(:last-child) {
            margin-right: 1.8rem;

            @media (max-width:575px) {
                margin-right: 1rem;
            }

            @media (max-width:991px) {
                margin-bottom: 1rem;

            }
        }
    }
}

.toggle_btn {
    padding: 0.7rem;
    width: max-content !important;
    display: flex;
    flex-direction: column;


    span {
        display: block;
        background-color: $baseclr;
        height: 0.3rem;
        transform-origin: left;
        width: 2.7rem;
        border-radius: 2rem;
        transition: 200ms ease-in-out;

        &:not(:last-child) {
            margin-bottom: 0.65rem;
        }
    }


    &.active {
        padding-left: 1.5rem;

        span {
            &:last-child {
                transform: rotate(-45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:first-child {
                transform: rotate(45deg);
            }
        }
    }
}
@import "../../../assets/theme/_var.scss";

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: $tableBg ;
    padding: 2rem;

    &_in {
        max-width: 50rem;
        width: 100%;
        text-align: center;
        padding: 5rem 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: $borderclr;

        .login_btn_box {
            margin-top: 4rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .login_btn {
                max-width: 100%;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
// @import '../../../assets/scss/_var.scss';
@import '../../../assets/theme/_var.scss';

.error_page {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 150px);
  justify-content: center;
  padding: 0px 20px;
  flex-direction: column;
  text-align: center;

  svg {
    max-width: 350px;
    width: 100%;

    @media (max-width: 1439px) {
      max-width: 250px;

    }

    @media (max-width: 575px) {
      max-width: 200px;

    }
  }

  &_icon {
    max-width: 14rem;
    max-height: 14rem;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 767px) {
      max-width: 10rem;
      max-height: 10rem;
    }
  }

  h2 {
    margin-top: 3rem;
    font-size: 5rem;
    font-weight: 600;

    @media (max-width: 1439px) {
      font-size: 4rem;
    }

    @media (max-width: 767px) {
      font-size: 3.4rem;
    }
  }

  h3 {
    font-size: 4rem;
    font-weight: 600;
    margin: 1rem 0 3rem;

    @media (max-width: 1439px) {
      font-size: 1.8rem;
    }

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  a {
    display: block;
    margin-top: 1rem;
    font-size: 2rem;
    color: $baseclr;

    transition: 0.3s ease-in-out;
  }
}
.tablebox {
    table {
        tbody {
            tr {
                td {
                    &:first-child {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
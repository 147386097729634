@import "../../../../assets/theme/_var.scss";


.enable_collateral {

  &_in {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      color: $lightText;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      max-width: 39rem;
      width: 100%;
      margin-bottom: 3rem;

      a {
        color: $baseclr;
      }

    }

    .in_content {
      width: 100%;
      background: $modalInnerBg;
      border-radius: 1rem;
      padding: 2rem;
      margin-bottom: 2rem;

      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.4rem;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          span {
            color: $lightText;
          }

          h5 {
            font-size: 1.6rem;
            font-weight: 500;

            svg {
              width: 2rem;
              height: 2rem;
            }
          }


        }
      }

      .progress_line {
        background: rgba($white, 0.1);
        border-radius: .6rem;
        width: 100%;
        height: 1.2rem;
        padding: .3rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2rem;

        span {
          background: #2bcf62;
          border-radius: .6rem;
          width: 40%;
          height: .6rem;
        }
      }
    }
  }
}
@import "../../../assets/theme/_var.scss";

.admin_header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #241128;


    &_in {
        padding: 2.6rem 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 9rem;

        img {
            flex-shrink: 0;
            max-width: 12rem;
            width: 100%;
        }


        @media (max-width: 1579px) {
            padding: 1.6rem 2rem;
        }

        @media (max-width: 1479px) {
            padding: 1.2rem 2rem;
        }

        .side_btns {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                &:not(:last-child) {
                    margin-right: 1rem;
                }

            }

            .sidebar_btn {
                background-color: transparent;
                padding: 0;
                border: 0;
                width: max-content;
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                    display: block;
                    background-color: $baseclr;
                    height: 0.3rem;
                    transform-origin: left;
                    width: 2.7rem;
                    border-radius: 2rem;
                    transition: 200ms ease-in-out;

                    &:not(:last-child) {
                        margin-bottom: 0.65rem;
                    }
                }

                &.active {
                    span {
                        &:last-child {
                            transform: rotate(-45deg);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }

                        &:first-child {
                            transform: rotate(45deg);
                        }
                    }
                }


            }

        }

    }

    .overlay {
        width: 100%;
        height: 100dvh;
        background-color: rgba($black, 0.2);
        position: fixed;
        z-index: -2;
        top: 0;
        left: 0;
        display: none;

        &.active {
            display: block;
        }

    }
}
@import "../../../assets/theme/_var.scss";

.setting {
    .submit_btn {
        margin-top: 2rem;
    }

    &_in {
        margin-top: 2rem;

        h3 {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }



    }

    .mid_setting {
        margin-top: 2rem;

        .row {
            margin: -1rem;

            >div {
                padding: 1rem;
            }
        }


    }

    .gradient_box {
        margin-top: 2rem;
        max-width: 103rem;
        width: 100%;

        h3 {
            margin-bottom: 2rem;
        }

        .check_box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                margin-bottom: 0rem;
            }
        }


    }



}
@import "../../../assets/theme/_var.scss";

.modal-backdrop.show {
    background: $black;
    pointer-events: none;
}

.custom-modal {
    .modal-dialog {
        padding: 0 1rem;
        // max-width: 100%;

        @media (max-width: 767px) {
            max-width: 45rem;
            margin: 0 auto;
        }
    }

    .modal-content {
        box-shadow: 0px 0px 16px 0px #00000024;
        background: linear-gradient(118.54deg,
                rgba(41, 27, 43, 0) 0%,
                rgba(41, 27, 43, 0.4) 100%),
            linear-gradient(129.58deg,
                rgba(41, 27, 43, 0.4) 0%,
                rgba(41, 27, 43, 0) 100%),
            linear-gradient(180deg,
                rgba(41, 27, 43, 0.7) 0%,
                rgba(17, 20, 25, 0.28) 100%);
        border-radius: 2rem;
        max-width: 100%;
        width: 100%;
        backdrop-filter: blur(13px);
        padding: 2.4rem 2.4rem 5rem 2.4rem;

        @media (max-width: 767px) {
            padding: 1.4rem 1.6rem 2rem 1.6rem;
        }
    }

    .modal-body {
        padding: 0;
    }

    &__action {
        line-height: 1;
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 2.6rem;
        height: 2.5rem;
        margin-left: auto;
        position: absolute;
        right: 2.4rem;


        @media (max-width: 767px) {
            top: 1rem;
            right: 1.5rem;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__header {
        transition: all ease-in-out 200ms;
        padding-bottom: 2.8rem;
        display: flex;

        @media (max-width: 767px) {
            padding-bottom: 1.6rem;
        }

        h2 {
            color: $white;
            text-align: center;
            font-size: 1.8rem;
            font-weight: 600;
            margin: 0 auto;
            display: flex;
            align-items: center;

            span {
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;
            }
        }
    }

}

.big-modal {
    .custom-modal {
        .modal-dialog {
            max-width: 120rem !important;
        }

        .modal-content {
            max-width: 100% !important;
        }
    }
}
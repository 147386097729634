@import "../../../assets/theme/_var.scss";

.lending_currency {
    &.currency_list {
        .table_sec {
            .table_box {
                .table {
                    thead {
                        tr {
                            th {

                                &:nth-child(4) {
                                    padding: 2rem 1rem;
                                }

                                &:nth-child(5) {
                                    padding: 2rem 1rem;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                cursor: unset;

                                &:nth-child(4) {
                                    padding: 2rem 1rem;
                                }

                                &:nth-child(5) {
                                    padding: 2rem 1rem;

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &_head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            width: max-content;
            padding: 0rem 2rem;
        }
    }

    &_in {
        margin-top: 2rem;

        .row {
            margin: -1rem;

            >div {
                padding: 1rem;
            }
        }

        .field {
            input {
                margin-bottom: 2rem;
            }


        }

    }

    .table_sec {
        margin-top: 2rem;
    }
}
@import '../../../assets/theme/_var.scss';

.custom_input {


  label {
    display: block;
    color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.5rem;

    @media (max-width:767px) {
      font-size: 1.4rem;
    }

    @media (max-width:575px) {
      font-size: 1.3rem;
    }

  }

  input {
    line-height: normal;
    max-width: 100%;
    width: 100%;
    height: 5rem;
    border-radius: 1rem;
    background-color: $borderclr;
    border: none;
    color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0 1.5rem;

    @media (max-width:767px) {
      font-size: 1.4rem;
    }

    @media (max-width:575px) {
      font-size: 1.3rem;
    }


    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $lightText;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }

  }

}
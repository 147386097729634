@import "../../../assets/theme/_var.scss";

.common-select-wrapper {
  margin-bottom: 2rem;

  label {
    display: block;
    color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.5rem;

    @media (max-width:575px) {
      font-size: 1.4rem;
    }

    @media (max-width:380px) {
      font-size: 1.3rem;
    }

  }

  .common-select {
    .react-select {
      &__control {
        line-height: normal;
        max-width: 100%;
        width: 100%;
        height: 5rem;
        border-radius: 1rem;
        background-color: $borderclr;
        border: none;
        color: $white;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 0 1.5rem;

        @media (max-width: 1679px) {
          font-size: 1.4rem;
        }

        &--is-focused {
          box-shadow: none;
        }

        &--menu-is-open {
          .react-select__indicators {
            svg {
              transform: rotate(180deg);


            }
          }
        }

        .custom_input {
          @media (max-width: 767px) {
            margin-bottom: 1rem;
          }

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: $white;
          }

        }

      }

      &__option {
        color: $white;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;

        &:hover {
          background-color: $baseclr !important;
        }

        &--is-focused {
          background-color: transparent;

        }

        &--is-selected {
          background-color: rgba($baseclr, $alpha: 0.7);

        }


      }

      &__single-value {
        color: $white;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
      }

      &__menu {
        background: $borderclr ;
      }

    }

    &__placeholder {
      color: $white;
    }

    &__input-container,
    &__single-value {
      margin: 0;
      color: $white;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-right: 0.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 767px) {
        font-size: 1.2rem;
      }

      &--is-disabled {
        color: $black;
      }
    }


    .react-select__indicators {
      svg {
        path {
          fill: $white;
          stroke: $white;
        }
      }


      .react-select__indicator-separator {
        display: none;

      }
    }
  }
}
@import '../../../../assets/theme/_var.scss';

.add_currency_modal {
    .modal-dialog {
        max-width: 54.2rem;

        .modal-content {
            padding: 1.6rem 3rem 3rem 3rem;

            .Createaddress_btn
            {
                width:100%;
                margin-top: 2rem;
            }

            .address_form {

                .address_btn {
                    margin: 3rem auto 0  auto;

                    padding: 0rem 2rem;
                }
              

                .successfully_msg {
                    color: $white;
                    text-align: center;
                    font-size: 1.6rem;
                    font-weight: 400;
                    margin-bottom: 2rem;

                }

                

            }

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2rem;
                margin: 2rem 0rem;
                background: $layout;
                border-radius: 1rem;

                li {
                    font-size: 1.5rem;
                    font-weight: 500;

                    a {
                        color: $white;
                        display: flex;
                        align-items: center;
                    }

                    img {
                        width: 3rem;
                        height: 3rem;
                        margin-right: 0.7rem;

                    }

                    svg {
                        width: 2rem;
                        height: 2rem;
                        margin-left: 0.7rem;

                        path {
                            stroke: $baseclr;
                        }
                    }
                }
            }




        }
    }
}
.spinner_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;

    .spinner-border {
        width: 5rem;
        height: 5rem;
        margin: 0 auto;
    }
}
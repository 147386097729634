@import "../../../assets/theme/_var.scss";

.shimmer {
  width: 200px;
  height: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  background-color: $borderclr ;

  @keyframes shimmer {
    0% {
      transform: translateY(-50%) translateX(-100%);
    }

    50%,
    100% {
      transform: translateY(-50%) translateX(70%);
    }
  }

  &::after {
    content: "";
    width: 300%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $borderclr2;
    animation: shimmer 2s ease-in-out infinite;
    border-radius: inherit;
  }
}
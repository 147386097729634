@import "../../../assets/theme/_var.scss";

.main_layout {
    background-image: url("../../../assets/images/landing-page.png");
    overflow-x: clip;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    font-family: $secondaryfont;
    position: relative;
    padding-left: 30rem;

    @media (max-width:767px) {
        padding-left: 0;

    }

    &_in {
        padding: 3rem;
    }


}
@import '../../../../assets/theme/var.scss';

.main_card {

    padding: 2.7rem 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    height: 100%;
    background: $tableBg;

    @media (max-width:1679px) {
        padding: 2rem 1.5rem;
    }

    .card_left {
        display: flex;
        align-items: center;

        span {
            background-color: $BoxOverlay;
            border-radius: 0.8rem;
            width: 7rem;
            height: 7rem;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 3rem;
                height: 3rem;

                path {
                    fill: white;
                }
            }

            @media (max-width:1679px) {
                width: 5rem;
                height: 5rem;

                svg {
                    width: 2.2rem;
                    height: 2.2rem;
                }
            }
        }

        .card_info {
            margin-left: 1.5rem;

            @media (max-width:1679px) {
                margin-left: 1rem;
            }

            p {
                font-size: 1.6rem;
                color: $lightText;

                @media (max-width:1679px) {
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                }
            }

            h6 {
                font-size: 2.2rem;
                font-weight: 600;
                margin-top: 1rem;

                @media (max-width:1679px) {
                    font-size: 1.8rem;
                    line-height: 1.6rem;
                }
            }
        }
    }


}
@import "../../../../assets/theme/_var.scss";

.setCollateralBtn {
  background-color: transparent;
  border: none;
  color: $baseclr;
  padding: 0;
  margin: 0;
  width: max-content;
  height: max-content;
  font-size: 1.6rem;

}

.confirm_logout {
  h4 {
    text-align: center;
  }

  .btn_pair {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3rem;


    button {
      width: 100%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.custom_input {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}
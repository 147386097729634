@import '../../../../assets/theme/_var.scss';

.confirm_logout {
    &.borrow_modal {

        .warning-text {
            text-align: start;
            margin-top: .2rem;

            margin-bottom: 3rem;

            @media (max-width:1399px) {
                margin-bottom: 2.2rem;
            }

            @media (max-width:575px) {
                margin-bottom: 1.6rem;
            }
        }
    }

    .modal-dialog {
        max-width: 54.2rem;

        .modal-content {
            padding: 1.6rem 3rem 3rem 3rem;

            .warnign-text {
                text-align: start;
            }

            .Createaddress_btn {
                width: 100%;
                margin-top: 2rem;
            }

            .address_form {

                .address_btn {
                    margin: 0 auto;
                    padding: 0rem 2rem;
                }


                .successfully_msg {
                    color: $white;
                    text-align: center;
                    font-size: 1.6rem;
                    font-weight: 400;
                    margin-bottom: 2rem;

                }



            }

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2rem;
                margin: 2rem 0rem;
                background: $layout;
                border-radius: 1rem;

                li {
                    font-size: 1.5rem;
                    font-weight: 500;

                    a {
                        color: $white;
                        display: flex;
                        align-items: center;
                    }

                    img {
                        width: 3rem;
                        height: 3rem;
                        margin-right: 0.7rem;

                    }

                    svg {
                        width: 2rem;
                        height: 2rem;
                        margin-left: 0.7rem;

                        path {
                            stroke: $baseclr;
                        }
                    }
                }
            }




        }
    }

    .btn_pair {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 3rem;


        button {
            width: 100%;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}
.copy_btn {
    width: 1.8rem;
    height: 1.8rem;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    margin-left: 0.4rem;
  }

.spinner_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;

    .spinner-border {
        width: 5rem;
        height: 5rem;
        margin: 0 auto;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./assets/theme/_var.scss";

html {
  font-size: 62.5%;
}

body {
  font-family: $secondaryfont;
  color: $white;
  background-color: $layout;
}

html,
body {
  overscroll-behavior-y: none;
}

img,
svg,
iframe,
canvas,
video {
  max-width: 100%;
}

p,
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin-bottom: 0;
}

.warning-text {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: $progress;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a,
span {
  display: inline-block;
  text-decoration: none;
}

.container,
.container-fluid {
  padding: 0 1.5rem;
}

.container {
  max-width: 142.6rem;

  @media (max-width: 1679px) {
    max-width: 122rem;
  }

  @media (max-width: 1479px) {
    max-width: 112rem;
  }

  @media (max-width: 1199px) {
    max-width: 92rem;
  }
}

w3m-modal {
  z-index: 1057;
}

.gradient_box {
  background: $tableBg;
  border-radius: 1rem;
  padding: 2rem;
  width: max-content;

}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: $tableBg;
}

::-webkit-scrollbar-thumb {
  background: $baseclr;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($baseclr, 0.5);
}

.error_msg {
  color: red;
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 0.3rem;

  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
}

.borrowing_table {
  .borrowing_progress_box {
    display: flex;
    align-items: center;
    width: 12rem;

    h2 {
      margin-bottom: 0 !important;
      margin-left: 0.5rem;
      font-size: 1.5rem;
      font-weight: 400;
    }

    .progress {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 0.3rem;
      width: 100%;

      span {
        background: $green;
        border-radius: 0.6rem;
        max-width: 100%;
        width: 0%;
        transition: 300ms ease-in-out;
      }
    }
  }
}

input[type='checkbox'] {
  cursor: pointer;
}

.spinner_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0rem;

  .spinner-border {
    width: 5rem;
    height: 5rem;
    margin: 0 auto;
    border-color: $baseclr;
    border-right-color: transparent;
  }
}
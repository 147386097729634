@import "../../../../assets/theme/_var.scss";


.confirm_logout {
  h4 {
    text-align: center;
  }

  .btn_pair {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3rem;


    button {
      width: 100%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &.borrow_pause_modal {
    .modal-content {
      padding: 4rem 3rem !important;
    }
  }
}
@import "../../../assets/theme/_var.scss";

.admin_dashboard {

    &_in {
        .row {
            margin: -1.5rem;

            >div {
                padding: 1.5rem;
            }
        }

        margin-top: 2rem;

        h1 {
            margin-bottom: 2rem;
        }
    }
}
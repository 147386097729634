.custom_checkbox {
  &.form-switch {
    .form-check-input {
      --bs-form-switch-bg: url("../../../assets/icons/round.svg") !important;
      width: 4.8rem;
      height: 2.8rem;
      // margin-left: -2.5em;
      background-image: var(--bs-form-switch-bg);
      background-position: left center;
      border-radius: 2em;
      transition: background-position 0.15s ease-in-out;
      background-size: 2rem;
      background-position: 0.4rem;
      outline: 0;
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1);

      &:focus {
        background-position: 0.4rem;
        --bs-form-switch-bg: url("../../../assets/icons/round.svg") !important;
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:checked {
        background-position: 2.2rem;
        --bs-form-switch-bg: url("../../../assets/icons/purpleround.svg") !important;
        background-color: #35173c;
      }
    }
  }
}

@import "../../../assets/theme/_var.scss";

.custom_pagination {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .pagination {
    margin-top: 3rem;

    li {
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.4rem;

      &:hover {
        background: rgba(33, 33, 34, 1);
      }

      &:last-child {
        margin-right: 0px;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        background: $gradient1 ;
        backdrop-filter: blur(15px);
        border: 0;
        width: 4.2rem;
        height: 3.5rem;
        color: $white;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: -0.18px;

        svg {
          width: 2rem;
          height: 2rem;

          path {
            fill: $white;
          }
        }

        @media (max-width: 1679px) {
          font-size: 1.6rem;
          width: 3.8rem;
          height: 3.6rem;
        }

        @media (max-width: 767px) {
          font-size: 1.4rem;
          width: 3.2rem;
          height: 3rem;
        }

      }

      &:hover {
        border-radius: 1rem;
      }

      &.active {
        a {
          background: transparent;
          border: 1px solid $baseclr;
          color: $baseclr;

          svg {
            path {
              fill: $baseclr;
            }
          }
        }
      }

      &.disabled {
        a {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
}